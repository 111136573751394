import React, { useEffect, useState } from "react";
import { Typography, Box, Avatar } from "@mui/material";
import api from "../api";

function Profile() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Récupérer les informations de l'utilisateur
    const fetchUser = async () => {
      try {
        const response = await api.get("/auth/user");
        setUser(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations utilisateur :",
          error
        );
      }
    };

    fetchUser();
  }, []);

  if (!user) {
    return <Typography>Chargement...</Typography>;
  }

  // Vérifier si user.avatar est défini
  const avatarUrl = user.avatar
    ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
    : `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5}.png`;

  return (
    <Box sx={{ mt: 4, textAlign: "center" }}>
      <Avatar
        src={avatarUrl}
        alt={user.username}
        sx={{ width: 100, height: 100, margin: "auto" }}
      />
      <Typography variant="h4" sx={{ mt: 2 }}>
        {user.username}#{user.discriminator}
      </Typography>
      {/* Afficher d'autres informations si nécessaire */}
    </Box>
  );
}

export default Profile;
