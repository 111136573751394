import React from "react";
import { Box, Typography, Link } from "@mui/material";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#000000",
        color: "#ffffff",
        py: 3,
        px: 2,
        mt: "auto",
        textAlign: "center",
      }}
    >
      <Typography variant="body2">
        Fait par {" "}
        <Link
          href="https://clouz.fr"
          color="inherit"
          underline="always"
        >
          clouz
        </Link>
      </Typography>
      <Typography variant="caption" sx={{ mt: 1, display: "block" }}>
        © {new Date().getFullYear()} DiscordPlace. Tous droits réservés.
      </Typography>
      <Typography variant="caption" sx={{ mt: 1, display: "block" }}>
        <Link href="/privacy" color="inherit" underline="hover">
          Politique de confidentialité
        </Link>{" "}
        |{" "}
        <Link href="/terms" color="inherit" underline="hover">
          Termes et conditions
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer;
