// src/api.js
import axios from "axios";
import i18n from "./i18n";

const api = axios.create({
  baseURL: "https://discordplace.com/api",
  withCredentials: true,
});

// Intercepteur pour ajouter la langue dans les en-têtes
api.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language;
  return config;
});

export default api;
