import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import {
  Grid,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import ServerCard from "./ServerCard";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { keyframes } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { BigInt } from 'big-integer';

function Home() {
  const { t } = useTranslation();
  const [sortOption, setSortOption] = useState("random");
  const [sortOrder, setSortOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredServers, setFilteredServers] = useState([]);
  const [favoriteServers, setFavoriteServers] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [shuffledServers, setShuffledServers] = useState([]);
  const [sortedServers, setSortedServers] = useState([]);
  const [hasUserSorted, setHasUserSorted] = useState(false);
  const [visibleServersCount, setVisibleServersCount] = useState(12);
  const [animateHeart, setAnimateHeart] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const {
    data: user,
    isLoading: userLoading,
    error: userError,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () => api.get('/auth/user').then((res) => res.data),
    retry: false,
  });

  const {
    data: userServers = [],
    isLoading: serversLoading,
    error: serversError,
  } = useQuery({
    queryKey: ['userServers'],
    queryFn: () => api.get('/servers').then((res) => res.data || []),
    enabled: !!user,
  });

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSortOption(value);

    if (value === "average_rating") {
      setSortOrder("desc"); // Forcer l'ordre décroissant pour 'average_rating'
    } else if (value === "random") {
      setSortOrder(null); // Pas d'ordre pour 'random'
    } else {
      setSortOrder("desc"); // Par défaut, ordre décroissant pour les autres
    }

    // Indiquer que l'utilisateur a effectué un tri
    setHasUserSorted(true);
  };

  const handleToggleFavorites = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
    setAnimateHeart(true);
  };

  // Réinitialiser l'animation après son exécution
  useEffect(() => {
    if (animateHeart) {
      const timer = setTimeout(() => setAnimateHeart(false), 500);
      return () => clearTimeout(timer);
    }
  }, [animateHeart]);

  const {
    data: servers = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["allServers", sortOption, sortOrder],
    queryFn: () => {
      const sortQuery = sortOption ? `sort=${sortOption}` : "";
      const orderQuery = sortOrder ? `&order=${sortOrder}` : "";
      const queryString = `/servers?${sortQuery}${orderQuery}`;
      return api.get(queryString).then((res) => res.data);
    },
  });

  useEffect(() => {
    if (Array.isArray(servers)) {
      let sorted = [...servers];

      sorted.sort((a, b) => {
        let comparison = 0;

        switch (sortOption) {
          case "average_rating":
            comparison = a.average_rating - b.average_rating;
            if (comparison === 0) {
              comparison = b.reviews_count - a.reviews_count;
            }
            break;
          case "voice_member_count":
            comparison = a.voice_member_count - b.voice_member_count;
            if (comparison === 0) {
              comparison = b.reviews_count - a.reviews_count;
            }
            break;
          case "active_chat_members_count":
            comparison =
              a.active_chat_members_count - b.active_chat_members_count;
            if (comparison === 0) {
              comparison = b.reviews_count - a.reviews_count;
            }
            break;
          case "net_change":
            comparison = a.net_change - b.net_change;
            if (comparison === 0) {
              comparison = b.reviews_count - a.reviews_count;
            }
            break;
          case "member_count":
            comparison = a.member_count - b.member_count;
            if (comparison === 0) {
              comparison = b.reviews_count - a.reviews_count;
            }
            break;
          case "random":
            comparison = Math.random() - 0.5;
            break;
          default:
            comparison = 0;
        }

        if (sortOrder === "desc") {
          comparison = -comparison;
        }

        return comparison;
      });

      setSortedServers(sorted);
    } else {
      setSortedServers([]);
    }
  }, [servers, sortOption, sortOrder]);

  useEffect(() => {
    if (Array.isArray(servers)) {
      let filtered = servers;

      // Filtrage par favoris
      if (showFavoritesOnly) {
        filtered = filtered.filter((server) =>
          favoriteServers.includes(server.id.toString())
        );
      }

      // Filtre par terme de recherche
      if (searchTerm) {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        filtered = filtered.filter(
          (server) =>
            server.name.toLowerCase().includes(lowercasedSearchTerm) ||
            (server.tags &&
              server.tags.some((tag) =>
                tag.toLowerCase().includes(lowercasedSearchTerm)
              ))
        );
      }

      // Filtre par catégorie sélectionnée
      if (selectedCategory) {
        filtered = filtered.filter(
          (server) =>
            Array.isArray(server.categories) &&
            server.categories.includes(selectedCategory)
        );
      }

      // Filtre par langue sélectionnée
      if (selectedLanguage) {
        filtered = filtered.filter(
          (server) =>
            server.languages && server.languages.includes(selectedLanguage)
        );
      }

      // Mise à jour des serveurs filtrés
      setFilteredServers(filtered);
    } else {
      setFilteredServers([]);
    }
  }, [
    servers,
    searchTerm,
    selectedCategory,
    favoriteServers,
    showFavoritesOnly,
    selectedLanguage,
    sortOption,
    sortOrder,
  ]);

  // Fonction pour récupérer les identifiants des serveurs favoris
  const fetchFavorites = () => {
    api
      .get("/favorites")
      .then((response) => {
        // Supposons que response.data.favorites est un tableau d'objets serveurs favoris
        const favoriteIds = response.data; // response.data est déjà un tableau d'IDs
        setFavoriteServers(favoriteIds || []);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des favoris :", error);
      });
  };

  // Appeler fetchFavorites au chargement du composant
  useEffect(() => {
    fetchFavorites();
  }, []);

  // Définir une carte de traduction des catégories
  const categoryKeyMap = {
    Général: "category_general",
    Musique: "category_music",
    Éducation: "category_education",
    Politique: "category_politics",
    Science: "category_science",
    Santé: "category_health",
    Art: "category_art",
    Business: "category_business",
    Sport: "category_sports",
    Quotidien: "category_daily_life",
    Technologie: "category_technology",
    Jeux: "category_games",
    Divers: "category_misc",
    Programmation: "category_programming",
    Sécurité: "category_security",
    Vidéo: "category_video",
    Image: "category_image",
    Audio: "category_audio",
    Chat: "category_chat",
    RSS: "category_rss",
    Streaming: "category_streaming",
    "Jeux Vidéo": "category_video_games",
    Roleplay: "category_roleplaying",
    Actualité: "category_news",
  };

  const allCategories = Array.from(
    new Set(servers.flatMap((server) => server.categories || []))
  ).filter(Boolean);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Exécutez la fonction de recherche ou mettez à jour l'état
      setSearchTerm(event.target.value);
    }
  };

  // Après avoir filtré les serveurs, nous allons les trier
  useEffect(() => {
    if (Array.isArray(filteredServers)) {
      let sorted = [];

      if (sortOption === "random" || sortOption === undefined) {
        if (shuffledServers.length === 0) {
          // Mélanger une seule fois
          const shuffled = [...filteredServers].sort(() => 0.5 - Math.random());
          setShuffledServers(shuffled);
          sorted = shuffled;
        } else {
          // Utiliser l'ordre mélangé précédemment
          sorted = shuffledServers;
        }
      } else {
        // Logique de tri pour les autres options
        sorted = [...filteredServers].sort((a, b) => {
          let comparison = 0;

          switch (sortOption) {
            case "average_rating":
              // Toujours trier en ordre décroissant pour la note moyenne
              comparison = b.average_rating - a.average_rating;
              if (comparison === 0) {
                // Tri secondaire par nombre d'avis en ordre décroissant
                comparison = b.reviews_count - a.reviews_count;
              }
              break;
            case "net_change":
              comparison = a.net_change - b.net_change;
              break;
            case "voice_member_count":
              comparison = a.voice_member_count - b.voice_member_count;
              break;
            case "active_chat_members_count":
              comparison =
                a.active_chat_members_count - b.active_chat_members_count;
              break;
            case "member_count":
              comparison = a.member_count - b.member_count;
              break;
            default:
              comparison = 0;
          }

          // Inverser le sens du tri si sortOrder est 'desc', sauf pour 'average_rating'
          if (sortOption !== "average_rating" && sortOrder === "desc") {
            comparison = -comparison;
          }

          return comparison;
        });
        // Réinitialiser shuffledServers lorsque l'option de tri n'est plus 'random'
        if (shuffledServers.length > 0) {
          setShuffledServers([]);
        }
      }

      setSortedServers(sorted);
    } else {
      setSortedServers([]);
    }
  }, [filteredServers, sortOption, sortOrder]);

  useEffect(() => {
    if (sortOption === "random") {
      setShuffledServers([]);
      setHasUserSorted(false);
    }
  }, [filteredServers]);

  useEffect(() => {
    if (hasUserSorted) {
      // Enregistrer le log ici
      api.post("/logs", {
        action: "sort_servers",
        details: {
          sort: sortOption,
          order: sortOrder,
        },
      });
    }
  }, [sortOption, sortOrder, hasUserSorted]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const heartBeat = keyframes`
    0% { transform: scale(1); }
    14% { transform: scale(1.3); }
    28% { transform: scale(1); }
    42% { transform: scale(1.3); }
    70% { transform: scale(1); }
    100% { transform: scale(1); }
  `;

  const handleAddServer = () => {
    if (user) {
      if (userServers.length > 1) {
        navigate('/dashboard');
      } else {
        const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
        const REDIRECT_URI = encodeURIComponent(
          process.env.REACT_APP_REDIRECT_URI_SERVERS_ADD
        );
        const RESPONSE_TYPE = 'code';
        const SCOPE = encodeURIComponent('bot applications.commands');
        const permissionsInt = BigInt(1) << BigInt(0); // CREATE_INSTANT_INVITE permission
        const permissions = permissionsInt.toString();
        const inviteURL = `https://discord.com/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&permissions=${permissions}`;

        window.location.href = inviteURL;
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        color: 'text.primary',
        minHeight: '100vh',
        padding: 3,
      }}
    >
      <Helmet>
        <html lang={i18n.language} />
        <title>{t("discover_best_discord_servers")} - DiscordPlace</title>
        <meta name="description" content={t("meta_description_home")} />
        <meta name="keywords" content={t("meta_keywords_home")} />
      </Helmet>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h3" gutterBottom>
            {t('discover_best_discord_servers')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              mt: 2,
              gap: 2,
            }}
          >
          </Box>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleAddServer}
              sx={{
                backgroundColor: "#43B581", // Couleur personnalisée
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#369b6d",
                },
                borderRadius: 8,
                padding: "10px 20px",
                fontWeight: "bold",
              }}
              disabled={user && userServers.length === 0}
            >
              {t("add_server")}
            </Button>
            <IconButton
              color="error"
              onClick={handleToggleFavorites}
              sx={{
                animation: animateHeart ? `${heartBeat} 1s ease-in-out` : 'none',
              }}
            >
              {showFavoritesOnly ? (
                <Favorite fontSize="large" />
              ) : (
                <FavoriteBorder fontSize="large" />
              )}
            </IconButton>
          </Box>
        </Grid>

        {/* Section des filtres et du tri */}
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            {/* Champ de recherche */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="outlined"
                placeholder={t("search_placeholder")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
                fullWidth
                inputProps={{
                  style: { fontSize: isMobile ? 12 : 16 },
                }}
              />
            </Grid>

            {/* Filtre par catégorie */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{t("filter_by_category")}</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  label={t("filter_by_category")}
                >
                  <MenuItem value="">
                    <em>{t("all_categories")}</em>
                  </MenuItem>
                  {allCategories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {t(categoryKeyMap[category] || category)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filtre par langue */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>{t("filter_by_language")}</InputLabel>
                <Select
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                  label={t("filter_by_language")}
                >
                  <MenuItem value="">
                    <em>{t("all_languages")}</em>
                  </MenuItem>
                  <MenuItem value="fr">Français</MenuItem>
                  <MenuItem value="en">English</MenuItem>
                  {/* Autres langues si nécessaire */}
                </Select>
              </FormControl>
            </Grid>

            {/* Option de tri */}
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1 }}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>{t("sort_by")}</InputLabel>
                    <Select
                      value={sortOption}
                      onChange={handleSortChange}
                      label={t("sort_by")}
                    >
                      <MenuItem value="net_change">{t("net_change")}</MenuItem>
                      <MenuItem value="average_rating">
                        {t("sort_option_rating")}
                      </MenuItem>
                      <MenuItem value="voice_member_count">
                        {t("sort_option_speaking")}
                      </MenuItem>
                      <MenuItem value="active_chat_members_count">
                        {t("sort_option_writing")}
                      </MenuItem>
                      <MenuItem value="member_count">
                        {t("sort_option_members")}
                      </MenuItem>
                      <MenuItem value="random">{t("sort_option_random")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {sortOption !== "random" && sortOption !== "average_rating" && (
                  <IconButton onClick={toggleSortOrder}>
                    {sortOrder === "asc" ? <ArrowUpward /> : <ArrowDownward />}
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Affichage des serveurs */}
        <Grid container spacing={2} justifyContent="left">
          {sortedServers.slice(0, visibleServersCount).map((server) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={server.id}>
              <ServerCard
                server={server}
                isFavorite={favoriteServers.includes(server.id.toString())}
                refreshFavorites={fetchFavorites}
              />
            </Grid>
          ))}
        </Grid>
        {visibleServersCount < sortedServers.length && (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button
              variant="contained"
              onClick={() => setVisibleServersCount(visibleServersCount + 12)}
            >
              Charger plus
            </Button>
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default Home;
