import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
} from "@mui/material";
import api from "../api";

// Fonction pour traduire le type de tri
const getSortTypeLabel = (sort) => {
  switch (sort) {
    case "average_rating":
      return "Note moyenne";
    case "voice_member_count":
      return "Membres en vocal";
    case "active_chat_members_count":
      return "Membres actifs en chat";
    case "net_change":
      return "Variation nette";
    case "member_count":
      return "Nombre de membres";
    default:
      return sort;
  }
};

const getActionLabel = (action) => {
  switch (action) {
    case "sort_servers":
      return "Tri des serveurs";
    case "add_server":
      return "Ajout d'un serveur";
    case "delete_server":
      return "Suppression d'un serveur";
    case "update_server":
      return "Mise à jour d'un serveur";
    case "add_review":
      return "Ajout d'un avis";
    case "delete_review":
      return "Suppression d'un avis";
    case "update_review":
      return "Mise à jour d'un avis";
    case "join_server":
      return "Rejoindre un serveur";
    case "get_servers":
      return "Consultation des serveurs";
    default:
      return action;
  }
};

// Fonction pour traduire l'ordre
const getOrderLabel = (order) => {
  switch (order) {
    case "asc":
      return "Croissant";
    case "desc":
      return "Décroissant";
    default:
      return order;
  }
};

function AdminLogsPage() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    api
      .get("/logs")
      .then((res) => setLogs(res.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Logs d'activité
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Utilisateur</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Nom du serveur</TableCell>
            <TableCell>ID du serveur</TableCell>
            <TableCell>ID de l'avis</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Commentaire</TableCell>
            <TableCell>Type de tri</TableCell>
            <TableCell>Ordre</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => {
            const details =
              typeof log.details === "string"
                ? JSON.parse(log.details)
                : log.details || {};
            return (
              <TableRow key={log.id}>
                <TableCell>
                  {new Date(log.timestamp).toLocaleString()}
                </TableCell>
                <TableCell>
                  {log.username}#{log.discriminator}
                </TableCell>
                <TableCell>{getActionLabel(log.action)}</TableCell>
                <TableCell>{details.serverName || ""}</TableCell>
                <TableCell>{details.serverId || ""}</TableCell>
                <TableCell>{details.reviewId || ""}</TableCell>
                <TableCell>{details.rating || ""}</TableCell>
                <TableCell>{details.comment || ""}</TableCell>
                <TableCell>
                  {log.action === "sort_servers"
                    ? getSortTypeLabel(details.sort) || ""
                    : ""}
                </TableCell>
                <TableCell>
                  {log.action === "sort_servers"
                    ? getOrderLabel(details.order) || ""
                    : ""}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default AdminLogsPage;
