import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import {
  Typography,
  CircularProgress,
  Grid,
  Box,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import { Link } from "react-router-dom";
import ServerCard from "./ServerCard";
import ModeratorActions from "./ModeratorActions";

function AdminPanel() {
  const { data, isLoading, error, refetch } = useQuery(["allServers"], () =>
    api.get("/admin/servers").then((res) => res.data.servers)
  );

  const [discordId, setDiscordId] = useState("");
  const [message, setMessage] = useState("");

  const handleDelete = async (serverId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce serveur ?")) {
      try {
        await api.delete(`/admin/servers/${serverId}`);
        alert("Serveur supprimé avec succès");
        refetch();
      } catch (error) {
        console.error("Erreur lors de la suppression du serveur :", error);
        alert("Erreur lors de la suppression du serveur");
      }
    }
  };

  const handleAddModerator = async () => {
    try {
      await api.post(`/users/${discordId}/setModerator`, {
        isModerator: true,
      });
      setMessage("Utilisateur défini comme modérateur avec succès");
      setDiscordId("");
    } catch (error) {
      console.error("Erreur lors de l'ajout du modérateur :", error);
      setMessage("Erreur lors de l'ajout du modérateur");
    }
  };

  const handleRemoveModerator = async () => {
    try {
      await api.post(`/users/${discordId}/setModerator`, {
        isModerator: false,
      });
      setMessage("Modérateur supprimé avec succès");
      setDiscordId("");
    } catch (error) {
      console.error("Erreur lors de la suppression du modérateur :", error);
      setMessage("Erreur lors de la suppression du modérateur");
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error)
    return <Typography>Erreur lors du chargement des serveurs.</Typography>;

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ mb: 2, textAlign: "center" }}>
        Panneau d'Administration
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/admin/logs"
        sx={{ mb: 2, display: "flex", justifyContent: "center" }}
      >
        Voir les Logs
      </Button>
 
      <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
        Gestion des modérateurs
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <TextField
          label="ID Discord de l'utilisateur"
          value={discordId}
          onChange={(e) => setDiscordId(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={handleAddModerator} sx={{ mr: 2 }}>
          Ajouter comme modérateur
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleRemoveModerator}
        >
          Supprimer le modérateur
        </Button>
      </Box>
      {message && (
        <Alert
          severity={message.startsWith("Erreur") ? "error" : "success"}
          onClose={() => setMessage("")}
          sx={{ mt: 2 }}
        >
          {message}
        </Alert>
      )}
      <Grid container spacing={2}>
        {data.map((server) => (
          <Grid item xs={12} sm={6} md={4} key={server.id}>
            <ServerCard
              server={server}
              handleDelete={handleDelete}
              isAdmin={true}
            />
          </Grid>
        ))}
      </Grid>
      <ModeratorActions />
    </Box>
  );
}

export default AdminPanel;
