import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import AdminPanel from "./components/AdminPanel";
import NotFound from "./components/NotFound";
import Login from "./components/Login";
import ServerDetails from "./components/ServerDetails";
import api from "./api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navbar from "./components/Navbar";
import CommunityChat from "./components/CommunityChat";
import AdminLogsPage from "./components/AdminLogsPage";
import ModeratorPanel from "./components/ModeratorPanel";
import InfoPage from "./components/InfoPage";
import Footer from "./components/Footer";
import Profile from "./components/Profile";
import Chat from "./components/CommunityChat";
import { Box } from "@mui/material";
import { CssBaseline } from '@mui/material';

function App() {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  const principalTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#0d47a1",
      },
      secondary: {
        main: "#ff9100",
      },
      background: {
        default: "#121212",
        paper: "#1e1e1e",
      },
      text: {
        primary: "#ffffff",
        secondary: "#b0b0b0",
      },
    },
    typography: {
      fontFamily: "Roboto, Arial",
    },
  });

  useEffect(() => {
    api
      .get("/auth/user")
      .then((response) => {
        setUser(response.data);
        setLoadingUser(false);
      })
      .catch(() => {
        setLoadingUser(false);
      });
  }, []);

  useEffect(() => {
    if (user) {
      api.post("/auth/visit").then(() => {});
    }
  }, [user]);

  if (loadingUser) {
    return <div>Chargement...</div>;
  }

  return (
    <ThemeProvider theme={principalTheme}>
      <CssBaseline />
      <Router>
        <Box
          sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
        >
          <Navbar user={user} />
          <Box sx={{ flex: 1 }}>
            <Routes>
              <Route
                path="/"
                element={<Home />}
              />
              <Route
                path="/servers/:id"
                element={<ServerDetails />}
              />
              <Route
                path="/dashboard"
                element={
                  user ? <Dashboard user={user} /> : <Navigate to="/" />
                }
              />
              <Route
                path="/admin"
                element={
                  user && user.isAdmin ? (
                    <AdminPanel user={user} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/admin/logs"
                element={
                  user && user.isAdmin ? (
                    <AdminLogsPage user={user} />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/chat" element={<CommunityChat />} />
              <Route
                path="/moderator"
                element={
                  user && user.isModerator ? (
                    <ModeratorPanel />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route path="/info" element={<InfoPage />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/chat" element={<Chat />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
