import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";

// Remplacez par l'URL de votre backend
const socket = io("/", {
  path: "/socket.io",
  transports: ["websocket", "polling"],
});

function CommunityChat() {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: () => api.get("/auth/user").then((res) => res.data),
  });

  const [lastMessageDeletedAt, setLastMessageDeletedAt] = useState(null);

  const { data: userServersData = [] } = useQuery({
    queryKey: ["userServers"],
    queryFn: () => api.get("/servers/user").then((res) => res.data || []),
    enabled: !!user,
  });

  const approvedServers = userServersData.filter(server => !server.is_in_waitlist);
  const canPost = user && approvedServers.length > 0;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogUserServers, setDialogUserServers] = useState([]); // Renommé
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const messagesEndRef = useRef(null);

  useEffect(() => {
    socket.emit("request chat history"); // Émettre un événement pour demander l'historique

    if (user) {
      socket.emit("user connected", user);
    }

    socket.on("chat history", (history) => {
      setMessages(history);
    });

    socket.on("chat message", (msg) => {
      setMessages((prev) => [...prev, msg]);
    });

    socket.on("message deleted", (msgId) => {
      setMessages((prev) => prev.filter((msg) => msg.id !== msgId));
    });

    socket.on("error", (msg) => {
      setErrorMessage(msg);
    });

    return () => {
      socket.off("chat history");
      socket.off("chat message");
      socket.off("message deleted");
      socket.off("error");
    };
  }, [user]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleDeleteMessage = (msgId) => {
    socket.emit("delete message", msgId, user.id);
    setLastMessageDeletedAt(new Date());
  };

  const sendMessage = () => {
    if (!user) return;

    const now = new Date();
    const lastDeletedAt = new Date(user.last_message_deleted_at);
    const cooldown = 10 * 60 * 1000; // 10 minutes en millisecondes

    if (lastDeletedAt && now - lastDeletedAt < cooldown) {
      alert(
        "Vous devez attendre 10 minutes après la suppression d'un message pour en envoyer un nouveau."
      );
      return;
    }

    if (message.trim() !== "") {
      socket.emit("chat message", {
        userId: user.id,
        username: user.username,
        avatar: user.avatar,
        message,
      });
      setMessage("");
      setErrorMessage(""); // Réinitialiser l'erreur
    }
  };

  const handleUsernameClick = async (userId, username) => {
    try {
      const response = await api.get(`/servers/user/${userId}`);
      setDialogUserServers(response.data);
      setSelectedUser(username);
      setOpenDialog(true);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des serveurs de l'utilisateur :",
        error
      );
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogUserServers([]);
    setSelectedUser(null);
  };

  const avatarUrl =
    user && user.avatar
      ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
      : user && user.discriminator
      ? `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5}.png`
      : `/images/default-avatar.jpg`;

  return (
    <Box sx={{ height: "80vh", display: "flex", flexDirection: "column" }}>
      {/* Liste des messages */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <List>
          {messages.map((msg) => {
            const avatarUrl =
              msg.avatar
                ? `https://cdn.discordapp.com/avatars/${msg.user_id}/${msg.avatar}.png`
                : msg.discriminator
                ? `https://cdn.discordapp.com/embed/avatars/${msg.discriminator % 5}.png`
                : `/images/default-avatar.jpg`;

            return (
              <ListItem key={msg.id}>
                <ListItemAvatar>
                  <Avatar src={avatarUrl} alt={msg.username} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Button
                      variant="text"
                      onClick={() => handleUsernameClick(msg.user_id, msg.username)}
                    >
                      {msg.username}
                    </Button>
                  }
                  secondary={
                    <>
                      {msg.message}
                      <Typography variant="caption" display="block" color="textSecondary">
                        {new Date(msg.timestamp).toLocaleString()}
                      </Typography>
                    </>
                  }
                />
                {user && user.id === msg.user_id && (
                  <IconButton onClick={() => handleDeleteMessage(msg.id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItem>
            );
          })}
        </List>
        <div ref={messagesEndRef} />
      </Box>

      {/* Barre d'entrée du message */}
      <Box sx={{ display: "flex", p: 1, borderTop: "1px solid #ccc" }}>
        <TextField
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Tapez votre message..."
          disabled={!canPost}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              sendMessage();
            }
          }}
        />
        <Button onClick={sendMessage} disabled={!canPost}>
          Envoyer
        </Button>
      </Box>

      {!canPost && (
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          Seuls les propriétaires de serveurs peuvent écrire dans le chat.
        </Typography>
      )}

      {errorMessage && (
        <Typography color="error" variant="body2">
          {errorMessage}
        </Typography>
      )}

      {/* Boîte de dialogue pour afficher les serveurs de l'utilisateur */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Serveurs de {selectedUser}</DialogTitle>
        <DialogContent>
          {dialogUserServers.length > 0 ? (
            <List>
              {dialogUserServers.map((server) => (
                <ListItem key={server.id}>
                  <ListItemText primary={server.name} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1">
              {selectedUser} ne possède pas de serveurs.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default CommunityChat;
