import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Slider,
  Rating,
} from "@mui/material";
import api from "../api";
import { useTranslation } from "react-i18next";

function ReviewForm({ serverId, onReviewSubmitted }) {
  const { t } = useTranslation();
  const [rating, setRating] = useState(2.5); // Note par défaut sur une échelle de 0.5 à 5
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      // Multiplier la note par 20 si le backend attend une valeur sur 100
      await api.post(`/servers/${serverId}/reviews`, {
        rating: rating, // Convertir la note de 0-5 à 0-100 si nécessaire
        comment,
      });
      setSuccess("Avis ajouté avec succès !");
      setRating(2.5);
      setComment("");
      onReviewSubmitted();
    } catch (err) {
      setError(
        err.response?.data?.message || "Erreur lors de l'ajout de l'avis."
      );
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">{t("add_review")}</Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            {t("rating")} :
          </Typography>
          <Rating
            value={rating}
            onChange={(event, newValue) => setRating(newValue)}
            precision={0.5}
            sx={{
              color: "#ffd700",
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ ml: "auto" }}
          >
            {t("submit")}
          </Button>
        </Box>
        <TextField
          label={t("comment")}
          multiline
          fullWidth
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ mt: 2 }}
        />
        {error && (
          <Typography color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
        {success && (
          <Typography color="primary" sx={{ mt: 1 }}>
            {success}
          </Typography>
        )}
      </form>
    </Box>
  );
}

export default ReviewForm;
