import React, { useEffect, useState } from "react";
import api from "../api";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

function ModeratorsList() {
  const [moderators, setModerators] = useState([]);

  useEffect(() => {
    api
      .get("/moderators")
      .then((res) => setModerators(res.data))
      .catch((error) =>
        console.error("Erreur lors de la récupération des modérateurs :", error)
      );
  }, []);

  return (
    <div>
      <Typography variant="h5">Liste des Modérateurs</Typography>
      <List>
        {moderators.map((moderator) => (
          <ListItem key={moderator.id}>
            <ListItemText
              primary={`${moderator.username}#${moderator.discriminator}`}
              secondary={`ID : ${moderator.id}`}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default ModeratorsList; 