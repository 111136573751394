import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton,
  Menu,
  Tooltip,
  Avatar,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Flag from "react-world-flags";
import LanguageIcon from "@mui/icons-material/Language";

// Mapping des codes de langue aux codes de pays
const languageToCountryCode = {
  fr: "FR",
  en: "GB",
  es: "ES",
  de: "DE",
  it: "IT",
  pt: "PT",
  ru: "RU",
  jp: "JP",
  // Ajoutez d'autres correspondances si nécessaire
};

function Navbar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [language, setLanguage] = React.useState(i18n.language || "fr");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/auth/user");
        setUser(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations utilisateur :",
          error
        );
        setUser(null);
      }
    };

    fetchUser();

    const interval = setInterval(fetchUser, 5000); // Vérifie toutes les 5 secondes

    return () => clearInterval(interval);
  }, []);

  const handleLogout = async () => {
    try {
      await api.post("/auth/logout");
      setUser(null);
      navigate("/");
    } catch (error) {
      console.error("Erreur lors de la déconnexion :", error);
    }
  };

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const avatarUrl =
    user && user.avatar
      ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
      : user
      ? `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5}.png`
      : `/images/default-avatar.jpg`; // Chemin vers une image par défaut si non connecté

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#1f1f1f", // Couleur de la barre de navigation
        color: "#fff", // Couleur du texte
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}
        >
          DiscordPlace
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMenuOpen}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/" onClick={handleMenuClose}>
                Accueil
              </MenuItem>
              <MenuItem component={Link} to="/info" onClick={handleMenuClose}>
                Info
              </MenuItem>
              {user ? (
                <>
                  <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={handleMenuClose}
                  >
                    Mon profil
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/dashboard"
                    onClick={handleMenuClose}
                  >
                    Tableau de bord
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/chat"
                    onClick={handleMenuClose}
                  >
                    Chat
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleLogout();
                      handleMenuClose();
                    }}
                  >
                    Déconnexion
                  </MenuItem>
                </>
              ) : (
                <MenuItem
                  onClick={handleMenuClose}
                  component="a"
                  href={`https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=identify%20email`}
                >
                  Se connecter
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/">
              Accueil
            </Button>
            <Button color="inherit" component={Link} to="/info">
              Info
            </Button>
            {user && user.isModerator && (
              <Button color="inherit" component={Link} to="/moderator">
                {t("moderator_panel")}
              </Button>
            )}
            {user && user.isAdmin && (
              <Button color="inherit" component={Link} to="/admin">
                {t("admin_panel")}
              </Button>
            )}
            <Button color="inherit" component={Link} to="/chat">
              {t("chat")}
            </Button>
            <Select
              value={language}
              onChange={handleChangeLanguage}
              sx={{ color: "white", mx: 2 }}
              variant="standard"
              disableUnderline
            >
              <MenuItem value="fr">Français</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </Select>
            {user ? (
              <>
                <Tooltip title={t("profile")}>
                  <IconButton onClick={handleMenuOpen} color="inherit">
                    <Avatar src={avatarUrl} alt={user.username} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={handleMenuClose}
                  >
                    Mon profil
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/dashboard"
                    onClick={handleMenuClose}
                  >
                    Tableau de bord
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/chat"
                    onClick={handleMenuClose}
                  >
                    Chat
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleLogout();
                      handleMenuClose();
                    }}
                  >
                    Déconnexion
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                color="inherit"
                href={`https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=identify%20email`}
              >
                Se connecter
              </Button>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
