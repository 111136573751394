import React, { useEffect, useState } from "react";
import api from "../api";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

function ModeratorActions() {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    api
      .get("/moderators/actions")
      .then((res) => setActions(res.data))
      .catch((error) =>
        console.error("Erreur lors de la récupération des actions :", error)
      );
  }, []);

  return (
    <div>
      <Typography variant="h5">Actions des Modérateurs</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Modérateur</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Détails</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map((action) => (
            <TableRow key={action.timestamp}>
              <TableCell>{action.username}</TableCell>
              <TableCell>{action.action}</TableCell>
              <TableCell>{JSON.stringify(action.details)}</TableCell>
              <TableCell>{new Date(action.timestamp).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default ModeratorActions; 