// src/components/Dashboard.js
import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import api from "../api";
import {
  CircularProgress,
  Button,
  Typography,
  TextField,
  Grid,
  Snackbar,
  Alert,
  Box,
  Card,
} from "@mui/material";
import ServerCard from "./ServerCard";
import BigInteger from "big-integer";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    data: user,
    isLoading: loadingUser,
    error: errorUser,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () => api.get("/auth/user").then((res) => res.data),
  });

  const {
    data: userServers = [],
    isLoading: loadingServers,
    error: errorServers,
  } = useQuery({
    queryKey: ["userServers"],
    queryFn: () =>
      api.get("/servers/user").then((response) => response.data || []),
    enabled: !!user,
    initialData: [],
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState("");
  const [availableTags, setAvailableTags] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const maxServersPerUser = 2;

  // Récupérer les catégories disponibles depuis le backend
  useEffect(() => {
    api
      .get("/categories")
      .then((res) => setAvailableCategories(res.data))
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories :", error);
        setAvailableCategories([]);
      });
  }, []);

  // Récupérer les tags disponibles depuis le backend
  useEffect(() => {
    api
      .get("/tags")
      .then((res) => setAvailableTags(res.data))
      .catch((error) => {
        console.error("Erreur lors de la récupération des tags :", error);
        setAvailableTags([]);
      });
  }, []);

  // Définition de inviteURL
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const REDIRECT_URI = encodeURIComponent(
    process.env.REACT_APP_REDIRECT_URI_SERVERS_ADD
  );
  const RESPONSE_TYPE = "code";
  const SCOPE = encodeURIComponent("bot applications.commands");
  const permissionsInt = BigInteger(1).shiftLeft(0); // CREATE_INSTANT_INVITE permission
  const permissions = permissionsInt.toString();
  const inviteURL = `https://discord.com/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&permissions=${permissions}`;

  const filteredServers = Array.isArray(userServers)
    ? userServers.filter((server) =>
        server.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  // Filtrer les serveurs approuvés et les serveurs en attente
  const approvedServers = filteredServers.filter(
    (server) => !server.is_in_waitlist
  );
  const waitlistServers = filteredServers.filter(
    (server) => server.is_in_waitlist
  );

  const mutation = useMutation(
    ({ id, categories, tags, description, reviews_count, languages }) =>
      api.put(`/servers/${id}`, {
        categories,
        tags,
        description,
        reviews_count,
        languages,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userServers");
        queryClient.invalidateQueries("servers");
        setMessage("Serveur mis à jour avec succès");
      },
      onError: (error) => {
        console.error("Erreur lors de la mise à jour du serveur:", error);
        setMessage("Erreur lors de la mise à jour du serveur");
      },
    }
  );

  const handleUpdate = (serverId, updatedData) => {
    const { languages } = updatedData;
    if (languages[0] === languages[1]) {
      setMessage(
        "La langue principale et secondaire doivent être différentes."
      );
      return;
    }
    mutation.mutate({ id: serverId, ...updatedData });
  };

  if (loadingUser || loadingServers) {
    return <CircularProgress />;
  }

  if (errorUser || errorServers) {
    console.error("Erreur lors du chargement des données :", {
      errorUser,
      errorServers,
    });
    return (
      <Alert severity="error">
        Une erreur s'est produite. Veuillez réessayer plus tard.
      </Alert>
    );
  }

  if (!user) {
    return (
      <Typography>
        Vous devez être connecté pour accéder à cette page.
      </Typography>
    );
  }

  const handleDelete = async (serverId, isWaitlist) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce serveur ?")) {
      try {
        if (isWaitlist) {
          // Si le serveur est en attente, supprimer depuis waitlist_servers
          await api.delete(`/waitlist/servers/${serverId}`);
        } else {
          // Sinon, supprimer depuis servers (nouvel endpoint)
          await api.delete(`/servers/${serverId}`);
        }
        queryClient.invalidateQueries("userServers");
      } catch (error) {
        console.error("Erreur lors de la suppression du serveur :", error);
        setMessage("Erreur lors de la suppression du serveur");
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Bienvenue, {user.username}#{user.discriminator}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button
          aria-label="Ajouter un serveur"
          variant="contained"
          href={inviteURL}
          sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 2 } }}
          disabled={userServers.length >= maxServersPerUser}
        >
          Ajouter un Serveur
        </Button>
        <TextField
          variant="outlined"
          placeholder="Rechercher un serveur..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
      </Box>

      {message && (
        <Alert
          severity={message.startsWith("Erreur") ? "error" : "success"}
          onClose={() => setMessage("")}
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}

      <Grid container spacing={2}>
        {/* Colonne des ServerCards */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {approvedServers.map((server) => (
              <Grid item xs={12} sm={6} key={server.id}>
                <Card
                  sx={{
                    backgroundColor: "#1e1e1e", // Couleur de fond sombre
                    color: "text.primary",
                    borderRadius: 2,
                    boxShadow: 3,
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.02)",
                    },
                    "&:active": {
                      transform: "scale(0.98)",
                    },
                  }}
                >
                  <ServerCard
                    server={server}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    availableCategories={availableCategories}
                    availableTags={availableTags}
                    isDashboard={true}
                    isWaitlist={server.is_in_waitlist}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Colonne des notifications */}
        <Grid item xs={12} md={4}>
          {(waitlistServers.length > 0 ||
            userServers.length >= maxServersPerUser) && (
            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Notifications
              </Typography>
              {waitlistServers.map((server) => (
                <Alert severity="info" sx={{ mb: 2 }} key={server.id}>
                  Votre serveur "<strong>{server.name}</strong>" a été soumis
                  avec succès. L'équipe le verra dans les plus brefs délais.
                </Alert>
              ))}
              {userServers.length >= maxServersPerUser && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                  Vous avez atteint la limite maximale de {maxServersPerUser}{" "}
                  serveurs.
                </Alert>
              )}
              {userServers.some(
                (server) => server.has_inaccessible_channels
              ) && (
                <Alert severity="info" sx={{ mt: 1 }}>
                  Vous avez des salons bloqués par le bot. Donnez-lui l'accès
                  pour débloquer la métrique des 7 derniers jours.
                </Alert>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
