import React, { useEffect, useState } from "react";
import api from "../api";
import { Typography, Box, Grid, Paper } from "@mui/material";
import {
  Group as GroupIcon,
  People as PeopleIcon,
  VoiceChat as VoiceChatIcon,
  HowToReg as HowToRegIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Apps as AppsIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";

function InfoPage() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    api
      .get("/stats")
      .then((res) => setStats(res.data))
      .catch((err) =>
        console.error("Erreur lors de la récupération des statistiques :", err)
      );
  }, []);

  if (!stats) {
    return <Typography>Chargement des statistiques...</Typography>;
  }

  const statItems = [
    {
      label: "Serveurs en attente",
      value: stats.waitlistServers,
      icon: <HourglassEmptyIcon fontSize="large" color="primary" />,
    },
    {
      label: "Serveurs actifs",
      value: stats.activeServers,
      icon: <AppsIcon fontSize="large" color="primary" />,
    },
    {
      label: "Membres totaux",
      value: stats.totalMembers,
      icon: <GroupIcon fontSize="large" color="primary" />,
    },
    {
      label: "Membres en ligne",
      value: stats.onlineMembers,
      icon: <PeopleIcon fontSize="large" color="primary" />,
    },
    {
      label: "Membres en vocal",
      value: stats.voiceMembers,
      icon: <VoiceChatIcon fontSize="large" color="primary" />,
    },
    {
      label: "Utilisateurs enregistrés",
      value: stats.registeredUsers,
      icon: <HowToRegIcon fontSize="large" color="primary" />,
    },
    {
      label: "Personnes ayant rejoint via le site",
      value: stats.joinCount,
      icon: <PersonAddIcon fontSize="large" color="primary" />,
    },
  ];

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Statistiques de la plateforme
      </Typography>
      <Grid container spacing={4}>
        {statItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
              {item.icon}
              <Typography variant="h6" sx={{ mt: 2 }}>
                {item.label}
              </Typography>
              <Typography variant="h3" color="primary">
                {item.value}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default InfoPage;
