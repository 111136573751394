import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
  Stack,
  Tooltip,
  TextField,
  Autocomplete,
  Grow,
  CardMedia,
  IconButton,
  MenuItem,
  Rating,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  People,
  RecordVoiceOver,
  TrendingUp,
  TrendingDown,
  Favorite,
  FavoriteBorder,
  OnlinePrediction,
  Draw,
  VoiceChat,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import api from "../api";
import Flag from "react-world-flags";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { keyframes } from "@mui/material/styles";

// Mapping des codes de langue aux codes de pays
const languageToCountryCode = {
  fr: "FR",
  en: "GB",
  es: "ES",
  de: "DE",
  it: "IT",
  pt: "PT",
  ru: "RU",
  jp: "JP",
  // Ajoutez d'autres correspondances si nécessaire
};

function ServerCard({
  server,
  handleDelete,
  handleUpdate,
  availableTags,
  availableCategories,
  isDashboard,
  isAdmin,
  refreshFavorites,
  isFavorite,
  isWaitlist,
}) {
  const { t } = useTranslation();
  // États locaux
  const [categories, setCategories] = useState(server.categories || []);
  const [tags, setTags] = useState(server.tags || []);
  const [description, setDescription] = useState(server.description || "");
  const [language, setLanguage] = useState(server.language || "fr");
  const [language1, setLanguage1] = useState(
    (server.languages && server.languages[0]) || ""
  );
  const [language2, setLanguage2] = useState(
    (server.languages && server.languages[1]) || ""
  );
  const [favorite, setFavorite] = useState(isFavorite);
  const [currentServer, setCurrentServer] = useState(server);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [animateHeart, setAnimateHeart] = useState(false);

  // Limites
  const maxCategories = 2;
  const maxTags = 3;
  const maxDescription = 400;
  const maxCharactersForTags = 10;

  const getCurrentUser = async () => {
    const user = await api.get("/auth/user");
    return user.data;
  };

  // Construction de l'URL complète de l'image
  const imageUrl = server.imageurl
    ? `https://cdn.discordapp.com/icons/${server.id}/${server.imageurl}.${
        server.imageurl.startsWith("a_") ? "gif" : "png"
      }?size=300&timestamp=${Date.now()}`
    : null;

  const handleJoinServer = async () => {
    // Envoyer une requête au backend pour enregistrer le log
    try {
      await api.post("/logs", {
        action: "join_server",
        details: { serverId: server.id },
      });
    } catch (error) {
      console.error("Erreur lors de l'envoi du log :", error);
    }

    // Ouvrir le lien d'invitation dans un nouvel onglet
    window.open(server.invite_link, "_blank");
  };

  const onHandleUpdate = () => {
    const languages = [language1];
    if (language2) languages.push(language2);
    handleUpdate(server.id, { categories, tags, description, languages });
  };

  const queryClient = useQueryClient();

  const toggleFavorite = async () => {
    try {
      if (favorite) {
        await api.delete(`/favorites/${server.id}`);
        setCurrentServer((prevServer) => ({
          ...prevServer,
          favorites_count: prevServer.favorites_count - 1,
        }));
      } else {
        await api.post(`/favorites/${server.id}`);
        setCurrentServer((prevServer) => ({
          ...prevServer,
          favorites_count: prevServer.favorites_count + 1,
        }));
      }

      setFavorite(!favorite);
      if (refreshFavorites) refreshFavorites();

      // Invalider les données pour refetch
      queryClient.invalidateQueries(["servers"]);
      setAnimateHeart(true);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du favori :", error);
    }
  };

  // Réinitialiser l'animation après son exécution
  useEffect(() => {
    if (animateHeart) {
      const timer = setTimeout(() => setAnimateHeart(false), 500);
      return () => clearTimeout(timer);
    }
  }, [animateHeart]);

  useEffect(() => {
    setLanguage1((server.languages && server.languages[0]) || "");
    setLanguage2((server.languages && server.languages[1]) || "");
  }, [server.languages]);

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  useEffect(() => {
    // Vérifiez si le serveur est dans les favoris
    api.get(`/favorites/${server.id}`).then((response) => {
      setFavorite(response.data.isFavorite);
    });
  }, [server.id]);

  // Vérifier si l'utilisateur est connecté
  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: () => api.get("/auth/user").then((res) => res.data),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const isUserLoggedIn = !!user;

  const navigate = useNavigate();

  const { data: currentUser } = useQuery(["currentUser"], () =>
    api
      .get("/auth/user")
      .then((res) => res.data)
      .catch(() => null)
  );

  return (
    <Grow in={true}>
      <Card
        sx={{
          maxWidth: 345,
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          borderRadius: 2,
          boxShadow: 6,
          backgroundColor: "#1e1e1e",
          color: "#fff",
          transition: "transform 0.3s, box-shadow 0.3s",
          "&:hover": {
            transform: "scale(1.02)",
            boxShadow: 10,
          },
          position: "relative",
          padding: 2,
          alignItems: "center",
        }}
      >
        <Link
          to={`/servers/${server.id}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {imageUrl ? (
            <CardMedia
              component="img"
              sx={{
                height: 130,
                width: 130,
                objectFit: "cover",
                margin: "auto",
              }}
              image={imageUrl}
              alt={server.name}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images/default.png";
              }}
            />
          ) : (
            <Box
              sx={{
                backgroundColor: "grey.500",
                height: 140,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" color="white">
                {server.name[0]}
              </Typography>
            </Box>
          )}
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexGrow: 1,
              paddingBottom: "16px !important",
              position: "relative",
              zIndex: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {server.name}
            </Typography>

            {/* Affichage de la description du serveur */}
            {!isDashboard && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  mb: 2,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {server.description || "Aucune description disponible."}
              </Typography>
            )}

            {/* Partie pour le Dashboard, permettant d'éditer la description */}
            {isDashboard && (
              <>
                {/* Gestion des catégories */}
                <Autocomplete
                  multiple
                  options={availableCategories}
                  value={categories}
                  onChange={(event, newValue) => {
                    if (newValue.length <= maxCategories) {
                      setCategories(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={`Catégories (max ${maxCategories})`}
                      placeholder="Sélectionner des catégories"
                    />
                  )}
                  sx={{ mb: 2 }}
                />
                {/* Gestion des tags */}
                <Autocomplete
                  multiple
                  freeSolo
                  options={availableTags}
                  value={tags}
                  onChange={(event, newValue) => {
                    if (
                      newValue.length <= maxTags &&
                      newValue.every(
                        (tag) => tag.length <= maxCharactersForTags
                      )
                    ) {
                      setTags(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={`Tags (max ${maxTags}, 10 caractères max par tag)`}
                      placeholder="Ajouter des tags"
                    />
                  )}
                  sx={{ mb: 2 }}
                />
                {/* Gestion de la description */}
                <TextField
                  label="Description"
                  multiline
                  rows={3}
                  value={description}
                  onChange={(e) => {
                    if (e.target.value.length <= maxDescription) {
                      setDescription(e.target.value);
                    }
                  }}
                  variant="outlined"
                  sx={{ mb: 2 }}
                  helperText={`${description.length}/${maxDescription} caractères`}
                />
                {/* Formulaire pour sélectionner la langue */}
                <TextField
                  select
                  label="Langue principale"
                  value={language1}
                  onChange={(e) => setLanguage1(e.target.value)}
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="fr">Français</MenuItem>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Español</MenuItem>
                  <MenuItem value="de">Deutsch</MenuItem>
                  <MenuItem value="it">Italiano</MenuItem>
                  <MenuItem value="pt">Português</MenuItem>
                  <MenuItem value="ru">Русский</MenuItem>
                  <MenuItem value="jp">日本語</MenuItem>
                  {/* Ajoutez d'autres langues */}
                </TextField>
                <TextField
                  select
                  label="Langue secondaire"
                  value={language2}
                  onChange={(e) => setLanguage2(e.target.value)}
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="">Aucune</MenuItem>
                  <MenuItem value="fr">Français</MenuItem>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Español</MenuItem>
                  <MenuItem value="de">Deutsch</MenuItem>
                  <MenuItem value="it">Italiano</MenuItem>
                  <MenuItem value="pt">Português</MenuItem>
                  <MenuItem value="ru">Русский</MenuItem>
                  <MenuItem value="jp">日本語</MenuItem>
                  {/* Ajoutez d'autres langues */}
                </TextField>
              </>
            )}

            {/* Les métriques */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Première ligne des métriques */}
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 1, justifyContent: "center" }}
              >
                {/* Nb de personnes en vocal */}
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Tooltip title={t("voice")}>
                    <RecordVoiceOver sx={{ color: "info.main" }} />
                  </Tooltip>
                  <Typography variant="body2">
                    {server.voice_member_count || 0}
                  </Typography>
                </Stack>
                {/* Nb de personnes à l'écrit les 7 derniers jours */}
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Tooltip title={t("active_7d")}>
                    <Draw sx={{ color: "success.main" }} />
                  </Tooltip>
                  <Typography variant="body2">
                    {server.active_7d !== null ? server.active_7d : 0}{" "}
                  </Typography>
                </Stack>
                {/* Nb de vocaux actifs */}
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Tooltip title="Salons vocaux actifs">
                    <VoiceChat color="primary" />
                  </Tooltip>
                  <Typography variant="body2">
                    {server.active_voice_channels_count || 0}
                  </Typography>
                </Stack>
              </Stack>

              {/* Deuxième ligne des métriques */}
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 1, justifyContent: "center" }}
              >
                {/* Nb de membres total */}
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Tooltip title={t("members")}>
                    <People sx={{ color: "primary.main" }} />
                  </Tooltip>
                  <Typography variant="body2">{server.member_count}</Typography>
                </Stack>
                {/* Nb de membres actifs total */}
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Tooltip title={t("online")}>
                    <OnlinePrediction color="warning" />
                  </Tooltip>
                  <Typography variant="body2">
                    {server.online_member_count || 0}
                  </Typography>
                </Stack>
                {/* Flux mensuel de membres */}
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Tooltip
                    title={`${t("monthly_flux")} (+${server.monthly_joins} | -${
                      server.monthly_leaves
                    })`}
                  >
                    {server.net_change >= 0 ? (
                      <TrendingUp sx={{ color: "success.main" }} />
                    ) : (
                      <TrendingDown sx={{ color: "error.main" }} />
                    )}
                  </Tooltip>
                  <Typography variant="body2">{server.net_change}</Typography>
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" color="textSecondary" component="p">
                  <Tooltip
                    title={
                      server.average_rating
                        ? `${Number(server.average_rating).toFixed(2)}/5`
                        : ""
                    }
                  >
                    <Rating
                      name="read-only"
                      value={server.average_rating}
                      precision={0.5}
                      readOnly
                    />
                  </Tooltip>
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ ml: 0.5 }}
                >
                  ({server.reviews_count || 0})
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                {server.languages &&
                  server.languages.map((lang) => {
                    const countryCode =
                      languageToCountryCode[lang] || lang.toUpperCase();
                    return <Flag key={lang} code={countryCode} height="16" />;
                  })}
              </Box>
            </Box>
          </CardContent>
        </Link>
        <CardActions
          sx={{
            justifyContent: "center",
            flexWrap: "wrap",
            position: "relative",
            zIndex: 2,
          }}
        >
          {server.invite_link ? (
            <Button
              size="small"
              variant="contained"
              href={server.invite_link}
              target="_blank"
              rel="noopener noreferrer"
              disabled={!server.invite_link}
              onClick={handleJoinServer}
              sx={{
                backgroundColor: "#5865F2",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#4854c7",
                },
              }}
            >
              {t("join_server")}
            </Button>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Invitation non disponible
            </Typography>
          )}
          {/* Boutons conditionnels pour le Dashboard */}
          {isDashboard && (
            <>
              {/* Bouton pour sauvegarder les modifications */}
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={onHandleUpdate}
                sx={{ maxWidth: "120px", margin: "5px" }}
              >
                {t("save")}
              </Button>
              {/* Bouton pour supprimer le serveur */}
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={() => handleDelete(server.id)}
              >
                {t("delete")}
              </Button>
            </>
          )}
          {/* Bouton de suppression pour l'administrateur */}
          {isAdmin && (
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={() => handleDelete(server.id)}
            >
              {t("delete")}
            </Button>
          )}
          {!isDashboard && !isAdmin && (
            <>
              {isUserLoggedIn && (
                <IconButton
                  color="error"
                  onClick={toggleFavorite}
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 35,
                    "&:hover": {
                      color: "#ff4081",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 28,
                    },
                  }}
                >
                  {favorite ? <Favorite /> : <FavoriteBorder />}
                </IconButton>
              )}
              {!currentUser && (
                <IconButton
                  color="error"
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 35,
                    animation: `${heartBeat} 1s ease-in-out`,
                  }}
                  onClick={() => navigate("/login")}
                >
                  <FavoriteBorder />
                </IconButton>
              )}
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ position: "absolute", top: 10, right: 24 }}
              >
                ({currentServer.favorites_count || 0})
              </Typography>
            </>
          )}
        </CardActions>
      </Card>
    </Grow>
  );
}

// Définir l'animation
const heartBeat = keyframes`
  0% { transform: scale(1); }
  14% { transform: scale(1.3); }
  28% { transform: scale(1); }
  42% { transform: scale(1.3); }
  70% { transform: scale(1); }
  100% { transform: scale(1); }
`;

export default ServerCard;
