import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <Typography variant="h4" gutterBottom>
        404 - Page Non Trouvée
      </Typography>
      <Typography variant="body1" gutterBottom>
        Oups! La page que vous recherchez n'existe pas.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Retour à l'accueil
      </Button>
    </div>
  );
}

export default NotFound;
