import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import api from "../api";
import ModeratorList from "./ModeratorsList.js";

function ModeratorPanel() {
  const [waitlistServers, setWaitlistServers] = useState([]);

  useEffect(() => {
    fetchWaitlistServers();
  }, []);

  const fetchWaitlistServers = async () => {
    try {
      const response = await api.get("/waitlist/servers");
      setWaitlistServers(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des serveurs en attente :",
        error
      );
    }
  };

  const handleApprove = async (serverId) => {
    try {
      await api.post(`/waitlist/servers/${serverId}/approve`);
      setWaitlistServers(
        waitlistServers.filter((server) => server.id !== serverId)
      );
    } catch (error) {
      console.error("Erreur lors de l'approbation du serveur :", error);
    }
  };

  const handleReject = async (serverId) => {
    try {
      await api.post(`/waitlist/servers/${serverId}/reject`);
      setWaitlistServers(
        waitlistServers.filter((server) => server.id !== serverId)
      );
    } catch (error) {
      console.error("Erreur lors du rejet du serveur :", error);
    }
  };

  return (
    <div>
      <ModeratorList />

      <Typography variant="h5" sx={{ mt: 4 }}>Serveurs en attente d'approbation</Typography>
      <List>
        {waitlistServers.map((server) => (
          <ListItem key={server.id}>
            <ListItemText
              primary={server.name}
              secondary={
                <>
                  <Typography>Propriétaire : {server.owner_id}</Typography>
                  <Typography>
                    Soumis le : {new Date(server.created_at).toLocaleString()}
                  </Typography>
                  <Typography>Description : {server.description}</Typography>
                </>
              }
            />
            <Button onClick={() => handleApprove(server.id)} color="primary">
              Approuver
            </Button>
            <Button onClick={() => handleReject(server.id)} color="secondary">
              Rejeter
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default ModeratorPanel;
