import React from "react";
import { Button, Typography, Box } from "@mui/material";

function Login() {
  return (
    <Box sx={{ textAlign: "center", mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Bienvenue sur DiscordPlace
      </Typography>
      <Typography variant="body1" gutterBottom>
        Veuillez vous connecter avec Discord pour accéder au site.
      </Typography>
      <Button variant="contained" color="primary" href="/api/auth/discord">
        Se connecter avec Discord
      </Button>
    </Box>
  );
}

export default Login;
