import React, { useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api";
import {
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  Tabs,
  Tab,
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Chip,
  Button,
  Rating,
  Grid,
  IconButton,
} from "@mui/material";
import ReviewForm from "./ReviewForm";
import {
  People,
  RecordVoiceOver,
  TrendingUp,
  TrendingDown,
  Category,
  Tag,
  Delete,
  Draw,
  OnlinePrediction,
} from "@mui/icons-material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";

function ServerDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const queryClient = useQueryClient();

  // Définir une carte de correspondance des catégories
  const categoryKeyMap = {
    Général: "category_general",
    Musique: "category_music",
    Éducation: "category_education",
    Politique: "category_politics",
    Science: "category_science",
    Santé: "category_health",
    Art: "category_art",
    Business: "category_business",
    Sport: "category_sports",
    Quotidien: "category_daily_life",
    Technologie: "category_technology",
    Jeux: "category_games",
    Divers: "category_misc",
    Programmation: "category_programming",
    Sécurité: "category_security",
    Vidéo: "category_video",
    Image: "category_image",
    Audio: "category_audio",
    Chat: "category_chat",
    RSS: "category_rss",
    Streaming: "category_streaming",
    "Jeux Vidéo": "category_video_games",
    Roleplay: "category_roleplaying",
    Actualité: "category_news",
  };

  // Récupérer les informations de l'utilisateur courant
  const { data: currentUser } = useQuery(["currentUser"], () =>
    api
      .get("/auth/user")
      .then((res) => res.data)
      .catch(() => null)
  );

  const {
    data: server,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["server", id],
    () => api.get(`/servers/${id}`).then((res) => res.data),
    {
      retry: false,
    }
  );

  const { refetch: refetchServers } = useQuery(["servers"], () =>
    api.get("/servers").then((res) => res.data.servers)
  );

  const handleReviewSubmitted = () => {
    queryClient.invalidateQueries(["server", id]); // Invalide le cache pour ce serveur
    refetch(); // Recharge les détails du serveur
    refetchServers(); // Recharge la liste des serveurs
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDeleteReview = async (reviewId) => {
    if (window.confirm(t("delete_review_confirmation"))) {
      try {
        await api.delete(`/reviews/${reviewId}`);
        // Mettre à jour les données du serveur
        refetch(); // Recharge les détails du serveur
        refetchServers(); // Recharge la liste des serveurs si nécessaire
      } catch (err) {
        console.error("Erreur lors de la suppression de l'avis :", err);
      }
    }
  };

  const handleJoinServer = async () => {
    // Informer le backend que l'utilisateur a rejoint le serveur
    if (currentUser) {
      await api.post(`/servers/${server.id}/join`);
    }
    // Rediriger l'utilisateur vers le lien d'invitation
    window.open(server.invite_link, "_blank");
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" sx={{ mt: 4 }}>
        {error.response && error.response.status === 401
          ? "Vous devez être connecté pour voir les détails de ce serveur."
          : "Erreur lors de la récupération du serveur."}
      </Typography>
    );
  }

  const imageUrl = server.imageurl
    ? `https://cdn.discordapp.com/icons/${server.id}/${server.imageurl}.png?size=300`
    : null;

  return (
    <Box sx={{ maxWidth: 900, margin: "auto", mt: 4 }}>
      <Card sx={{ boxShadow: 3 }}>
        <CardMedia
          component="img"
          image={imageUrl || "/images/default-server-image.png"}
          alt={server.name}
          sx={{ height: 130, width: 130, objectFit: "cover", margin: "auto" }}
        />
        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom align="center">
                {server.name}
              </Typography>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  href={server.invite_link}
                  target="_blank"
                  sx={{ mb: 2 }}
                  onClick={handleJoinServer}
                >
                  {t("join_server")}
                </Button>
              </Box>

              {/* Affichage des catégories */}
              {server.categories && server.categories.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    <Category sx={{ verticalAlign: "middle", mr: 1 }} />
                    {t("categories")}
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {server.categories.map((category) => (
                      <Chip
                        key={category}
                        label={t(categoryKeyMap[category] || category)}
                        color="primary"
                        variant="outlined"
                        sx={{ mb: 1 }}
                      />
                    ))}
                  </Stack>
                </Box>
              )}

              {/* Affichage des tags */}
              {server.tags && server.tags.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    <Tag sx={{ verticalAlign: "middle", mr: 1 }} />
                    {t("tags")}
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {server.tags.map((tag) => (
                      <Chip
                        key={tag}
                        label={`#${tag}`}
                        variant="outlined"
                        color="secondary"
                        sx={{ mb: 1 }}
                      />
                    ))}
                  </Stack>
                </Box>
              )}

              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                sx={{ mb: 2 }}
                variant="fullWidth"
              >
                <Tab label={t("information")} />
                <Tab label={t("reviews")} />
              </Tabs>

              <Box sx={{ mt: 2 }}>
                {tabValue === 0 && (
                  <>
                    <Typography variant="body1" paragraph>
                      {server.description || t("no_description")}
                    </Typography>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      sx={{ mt: 2 }}
                      justifyContent="center"
                    >
                      {/* Membres */}
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Tooltip title={t("members")}>
                          <People color="primary" />
                        </Tooltip>
                        <Typography variant="body2">
                          {server.member_count}
                        </Typography>
                      </Stack>
                      {/* Membres en ligne */}
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Tooltip title={t("online")}>
                          <OnlinePrediction color="warning" />
                        </Tooltip>
                        <Typography variant="body2">
                          {server.online_member_count || 0}
                        </Typography>
                      </Stack>
                      {/* Actifs (7 jours) */}
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Tooltip title={t("active_7d")}>
                          <Draw color="success" />
                        </Tooltip>
                        <Typography variant="body2">
                          {server.active_7d !== null
                            ? `${server.active_7d} `
                            : "n/a"}
                        </Typography>
                      </Stack>

                      {/* En vocal */}
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Tooltip title={t("voice")}>
                          <RecordVoiceOver color="info" />
                        </Tooltip>
                        <Typography variant="body2">
                          {server.voice_member_count || 0}
                        </Typography>
                      </Stack>

                      {/* Flux mensuel */}
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Tooltip title={t("monthly_flux")}>
                          {server.net_change >= 0 ? (
                            <TrendingUp color="success" />
                          ) : (
                            <TrendingDown color="error" />
                          )}
                        </Tooltip>
                        <Typography variant="body2">
                          {server.net_change}
                        </Typography>
                      </Stack>

                      {/* Salons vocaux actifs */}
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Tooltip title="Salons vocaux actifs">
                          <VoiceChatIcon color="primary" />
                        </Tooltip>
                        <Typography variant="body2">
                          {server.active_voice_channels_count || 0}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 1,
                      }}
                    >
                      <Typography variant="h6" component="div">
                        <Tooltip
                          title={
                            server.average_rating
                              ? `${Number(server.average_rating).toFixed(2)}/5`
                              : ""
                          }
                        >
                          <Rating
                            name="read-only"
                            value={server.average_rating}
                            precision={0.5}
                            readOnly
                          />
                        </Tooltip>
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ ml: 0.5 }}
                      >
                        ({server.reviews_count || 0})
                      </Typography>
                    </Box>
                  </>
                )}
                {tabValue === 1 && (
                  <>
                    <ReviewForm
                      serverId={id}
                      onReviewSubmitted={handleReviewSubmitted}
                    />
                    {server.reviews && server.reviews.length > 0 ? (
                      <List>
                        {server.reviews.map((review) => (
                          <ListItem key={review.id} alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar
                                alt={review.username}
                                src={review.avatarUrl}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography variant="body1">
                                    {review.username}
                                  </Typography>
                                  <Tooltip title={`${review.rating}/5`}>
                                    <Rating
                                      value={review.rating}
                                      readOnly
                                      precision={0.01}
                                      sx={{ ml: 2 }}
                                    />
                                  </Tooltip>
                                  {currentUser &&
                                    currentUser.id === review.user_id && (
                                      <IconButton
                                        color="error"
                                        onClick={() =>
                                          handleDeleteReview(review.id)
                                        }
                                        sx={{ ml: 1 }}
                                      >
                                        <Delete />
                                      </IconButton>
                                    )}
                                </Box>
                              }
                              secondary={
                                <>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {review.comment}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {format(
                                      new Date(review.created_at),
                                      "dd MMMM yyyy",
                                      { locale: fr }
                                    )}
                                  </Typography>
                                </>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography>{t("no_comments_yet")}</Typography>
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ServerDetails;
